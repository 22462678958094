import React from 'react';
import Layout from '../components/layout';
import "../styles/app.scss"
import { Link } from 'gatsby';

export default function Home() {

  if (typeof window !== "undefined") {
    if(window.utag){
      window.utag.view({page_subcategory: "economic-updates"})
    }
  }

  return (
    <Layout
      title='Economic Updates'
      metaDescription="HSBC Intermediaries Economic Updates"
    >
      <div className='economic-page'>
        <h1 className='page-title'>Economic updates</h1>
        <p>HSBC UK is excited to be bringing you another detailed update on the current mortgage market.</p>
        <p>Join Tracie Burton, Senior Corporate Account Manager, and our Group Economist for their latest <br />views on the global and UK economy. They will also be touching on the recent Autumn Statement <br />and the impact this will have on the market.</p>
        <p className='red'>How to register</p>
        <p>To register for one of the webinars, please click on your preferred option below and <br />enter your details. Once confirmed, please save the invitation to your calendar.</p>
        <Link className='economic-page__link' to='https://hsbc.zoom.us/webinar/register/WN_FK5jIX3xScS3uB_6liphyw' target='_blank'><strong>Monday 27<sup>th</sup> November &ndash; 14:00pm</strong></Link>
        <Link className='economic-page__link' to='https://hsbc.zoom.us/webinar/register/WN_jEpJzp9ASeu7NspzX9758A' target='_blank'><strong>Friday 8<sup>th</sup> December &ndash; 10:30am</strong></Link>
        <p>All webinars will last approximately 45 minutes.</p>
        <p>We look forward to seeing you.</p><br /><br />
      </div>
    </Layout>
  );
}